import { isAuth } from "../stores/user.js";
import { authorize } from "./authorize.js";


export async function fetchAPI(endpoint, method, payload, credentials = false) {
    let options = {
        method: method
    };

    if (credentials) {
        options["credentials"] = "include";
    }


    if (payload) {
        options["body"] = JSON.stringify(payload);
    }


    if (method !== "GET") {
        options["headers"] = {
            ...options["headers"],
            "Content-Type": "application/json"
        };
    }

    let response = await fetch(`${import.meta.env.VITE_API_URL}${endpoint}`, options);
    console.log('Response code: ' + response.status);
    if (response.status == 401) {
        console.log('Attempting Auth Refresh');
        let refreshed = await authorize();
        if (refreshed) {
            console.log('Refresh Succeeded');
            return fetchAPI(endpoint, method, payload, credentials);
        } else {
            console.log('Refresh Failed');
            isAuth.set("loading");
        }
    }

    return response;

}
